import { useAppAuthorisationList } from "@gocardless/api/dashboard/app-authorisation";
import {
  AppAuthorisationResource,
  CreditorSelfResponseBody,
  CreditorsVerificationStatus,
} from "@gocardless/api/dashboard/types";

import { UseSetupPage } from "../routing/types";

import { Partner } from "./types";
import { getPartnerApp, setPostOnboardingUrl } from "./helpers";

import { useVerificationStatus } from "src/libraries/creditor/hooks";
import { Route } from "src/common/routing";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";

export interface UseAccountStatus extends UseSetupPage {
  appAuthorisations?: AppAuthorisationResource[];
  partner?: Partner;
  status: CreditorsVerificationStatus;
  mutate: () => Promise<CreditorSelfResponseBody | undefined>;
}

export const useAccountStatus = (): UseAccountStatus => {
  const appAuthorisations = useAppAuthorisationList();

  const { isVariationOn: isPartnerMerchantOnboardingImprovementsEnabled } =
    useOptimizelyVariation({
      flag: OptimizelyFlag.VERIFICATIONS_PARTNER_MERCHANT_ONBOARDING_IMPROVEMENTS,
    });

  const apps = isPartnerMerchantOnboardingImprovementsEnabled
    ? setPostOnboardingUrl(appAuthorisations?.data?.app_authorisations)
    : appAuthorisations?.data?.app_authorisations;

  const {
    loaded: verificationStatusLoaded,
    status,
    mutate,
  } = useVerificationStatus();

  return {
    loaded: verificationStatusLoaded && !!appAuthorisations,
    partner: getPartnerApp(apps),
    status,
    mutate,
    appAuthorisations: apps,
    route: Route.AccountStatus,
    completed: false, // last page in the setup flow, we want to always fallback to this.
  };
};
